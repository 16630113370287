export enum ConsentStatus {
  Accepted = 'accepted',
  Rejected = 'rejected',
  Timeout = 'timeout',
  Unknown = 'unknown'
}

export enum Store {
  HPID_COUNTRY = 'hpid-country'
}
