import { ConsentStatus } from '../utils/enums';
import { getCookie } from './cookies';

const hasUserAcceptedConsents = (): boolean => {
  const consentCookie = getCookie('OptanonConsent');
  if (!consentCookie) return false;

  const groups = new URLSearchParams(consentCookie).get('groups');
  return groups?.includes('2:1') || false;
};

const hasUserMadeConsentDecision = (): boolean => {
  return !!getCookie('OptanonAlertBoxClosed');
};

const waitForConsent = async (
  interval = 1000,
  timeout = 30000
): Promise<ConsentStatus> => {
  const startTime = Date.now();

  const shell = window?.Shell?.v1;

  if (!shell) {
    console.error('Shell is not defined');
    return Promise.resolve(ConsentStatus.Timeout);
  }

  return new Promise((resolve) => {
    const checkConsent = () => {
      if (hasUserMadeConsentDecision()) {
        if (hasUserAcceptedConsents()) {
          resolve(ConsentStatus.Accepted);
        } else {
          resolve(ConsentStatus.Rejected);
        }
      } else if (Date.now() - startTime >= timeout) {
        resolve(ConsentStatus.Timeout);
      } else {
        setTimeout(checkConsent, interval);
      }
    };

    checkConsent();
  });
};

export default waitForConsent;
