import { Store } from 'src/utils/enums';
import readOnlyDataStreamer from 'src/utils/readOnlyDataStreamer';
import getFeatureFlagValue from 'src/utils/getFeatureFlagValue';
import { BackgroundTasksOptionsType } from 'src/backgroundTaskService/types';

/**
 * Background Task: bgtSetHpIdCountry
 *
 * This function reads the country information from the HP ID and sets it in the Store using ClientOS background tasks.
 *
 * Process Details:
 * 1. Check if the Shell is defined.
 * 2. Create a logger instance for logging purposes.
 * 3. Check the feature flag value 'save-country-info-hpid-clientos-store'.
 * 4. If the feature flag is true, use the readOnlyDataStreamer utility to fetch the country information.
 * 5. Set the fetched country information in the Store.
 * 6. Retry the operation up to maxAttempts times if it fails.
 *
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 */
export const bgtSetHpIdCountry = async (
  options: BackgroundTasksOptionsType
): Promise<void> => {
  const shell = window?.Shell?.v1;
  if (!shell || !shell.logger) {
    console.error('bgtSetHpIdCountry: Shell or logger is not defined');
    return;
  }

  const logger = shell.logger.createLoggerInstance({
    preffixLog: 'bgtSetHpIdCountry:'
  });

  const maxAttempts = options.data?.maxAttempts || 1;
  let attempt = 0;
  let success = false;

  let featureFlagValue: boolean;
  try {
    featureFlagValue = await getFeatureFlagValue(
      logger,
      'save-country-info-hpid-clientos-store'
    );
  } catch (error) {
    logger.error('Error fetching feature flag value', error);
    return;
  }

  if (!featureFlagValue) {
    return;
  }

  while (attempt < maxAttempts && !success) {
    attempt++;
    try {
      await readOnlyDataStreamer('country', (country: string) => {
        shell.store.setState({ [Store.HPID_COUNTRY]: country });
      });
      success = true;
    } catch (error) {
      logger.error(
        `Error setting country to store on attempt ${attempt}`,
        error
      );
      if (attempt >= maxAttempts) {
        logger.error('Max attempts reached, operation failed');
      }
    }
  }
};
