export const getHpIdMainUrl = () => {
  const backgroundTasksProperties = (window as any)?.Shell?.manifest?.services
    ?.backgroundTasks?.backgroundTaskRegistrarList[0]?.properties;

  const hpIdBaseUrl = backgroundTasksProperties?.hpIdBaseUrl;
  const hpIdScriptPath = backgroundTasksProperties?.hpIdScriptPath;

  return `${hpIdBaseUrl}${hpIdScriptPath}`;
};

export default getHpIdMainUrl;
